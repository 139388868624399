<template>
  <div style="width: 100%">
    <div style="margin-bottom: 45px; box-shadow: 0 40px 32px -24px rgba(0, 0, 0, 0.4)">
      <video
        style="width: 100%; height: 100%; min-height: 300px"
        ref="videoPlayer"
        class="video-js vjs-theme-forest"></video>
    </div>
    <div class="my-10">
      <v-row
        no-gutters
        class="col-12 mb-6 px-0 py-0"
        justify="space-between"
        align="center">
        <div class="course-title">
          {{ course.title }}
        </div>
        <v-btn
          icon
          large
          style="transform: translate(15px)"
          :loading="isToggleFavoriteLoading"
          :disabled="isToggleFavoriteLoading"
          @click="toggleFavouriteStatus">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="course.isFavorite" v-bind:height="'24'" v-bind:width="'24'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.613 2.053a3.6 3.6 0 00-5.1 0L7 2.573l-.513-.52a3.6 3.6 0 00-5.1 0c-1.414 1.414-1.5 3.8.28 5.614L7 13l5.333-5.333c1.78-1.814 1.694-4.2.28-5.614z" fill="var(--v-primaryColor-base)"/></svg>
          <img
            v-else
            height="24"
            width="24"
            :src="heartIconDefault" />
        </v-btn>
        <CompleteButton
          :course="this.course"
          @completeCourse="$emit('completeCourse')" />
      </v-row>
    </div>
  </div>
</template>

<script>
  import VideoJsPlayerMixin from '@/components/courses/course-details/video-js-player/VideoJsPlayer.mixin.vue';
  export default {
    mixins: [VideoJsPlayerMixin],
  };
</script>

<style lang="css" scoped>
  .course-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.16em;
    text-align: left;
    text-transform: uppercase;
    color: #000000;
    max-width: 75%;
  }
  .course-complete-button {
    border: 2px solid var(--v-offWhite-base) !important;
    border-radius: 28px !important;
    font-weight: 600;
  }
</style>

<style lang="scss">
  .vjs-theme-forest {
    --vjs-theme-forest--primary: transparent;
    --vjs-theme-forest--secondary: #fff;
  }
  /* Play button in the middle of the video */
  .vjs-theme-forest .vjs-big-play-button {
    background: var(--v-primaryColor-base) !important;
    top: 55%;
    left: 55%;
    width: 56px;
    height: 56px;
    opacity: 0.88;
    border-radius: 50%;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url('../../../../assets/video/vjs-big-play-button.svg');
    }
  }

  /* Control bar */
  .vjs-theme-forest .vjs-control-bar {
    background-color: rgba(var(--v-primaryColor-rgb), 0.5);
    margin-bottom: 0;
    padding-block: 0.3rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    height: 38px;
  }

  /* Start/pause button */
  .vjs-theme-forest .vjs-play-control .vjs-icon-placeholder:before,
  .vjs-theme-forest .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    color: transparent;
    background-color: transparent;
    background-position: -5px -4px;
    background-size: 35px;
    content: '';
    width: 1.7rem;
    height: 2.7rem;
  }
  .vjs-theme-forest .vjs-play-control .vjs-icon-placeholder:before {
    background-image: url('../../../../assets/video/play.png');
  }
  .vjs-theme-forest .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    background-image: url('../../../../assets/video/pause.png');
  }

  /* Maximize button */
  .vjs-theme-forest .vjs-fullscreen-control .vjs-icon-placeholder:before {
    content: '';
    background-image: url('../../../../assets/video/maximize.png');
    background-position: 5px -5px;
  }
  .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
    content: '';
  }

  /* Picture in picture */
  .video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before {
    content: '';
    background-image: url('../../../../assets/video/picture-in-picture.png');
    background-position: 5px -5px;
  }

  /* Time remaining */
  .video-js .vjs-time-control {
    display: none;
  }

  /* Progress bar */
  .video-js .vjs-load-progress {
    background-color: var(--v-offWhite-base) !important;
    width: 100% !important;
    height: 4px !important;
    border-radius: 9999px !important;
  }
  .video-js .vjs-play-progress {
    background-color: var(--v-primaryColor-base);
  }
  /* Remove playback rate */
  .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
    display: none !important;
  }

  .vjs-poster {
    background-size: cover !important;
  }
</style>
